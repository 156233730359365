module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-plausible@0.0.7_gatsby@5.14.0_babel-eslint@10.1.0_eslint@8.57.1__react-dom@18.3_wn2eqquc5gwvybtrmymvl25owa/node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"datawisp.io"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.0_babel-eslint@10.1.0_eslint@8.57.1__react-dom@18.3.1_react@18.3.1__react@18.3.1__ldewt4diodfdhjkqxvukpn3q5i/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
